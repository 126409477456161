import { useEffect } from 'react'
import tw from 'twin.macro'

import FlexSection from '@components/FlexSection'

import { componentLoaded, findColors } from '@utils/functions'
import MiniPost from '@components/MiniPost'

import {
  WpPage_Acfpagefields_Content_BlogPosts,
  WpPrograma_Acfpagefields_Content_BlogPosts
} from '@_types/graphql-types'

type contentType =
  | WpPage_Acfpagefields_Content_BlogPosts
  | WpPrograma_Acfpagefields_Content_BlogPosts

const BlogPosts = ({
  data,
  index,
  tabs
}: {
  data: contentType
  index: string
  tabs?: boolean
}) => {
  const { description, title, bgColor, category, tagTitle } = data

  const sectionColors = findColors(bgColor)

  const postsCount = category.posts.nodes.length

  useEffect(() => {
    componentLoaded(title)
  }, [])

  return (
    <FlexSection
      index={index}
      title={title}
      tagTitle={tagTitle}
      colors={sectionColors}
      alignTitle="center"
      id={title || ''}
      paddingBottom
      paddingTop
      tabs={tabs}
    >
      {description && (
        <div
          tw="prose mx-auto mb-16 text-center!"
          css={[sectionColors.text === 'light' && tw`text-white!`]}
        >
          {description && description}
        </div>
      )}
      <div
        tw="grid gap-6 max-w-6xl mx-auto"
        css={[
          postsCount === 1 && tw`max-w-2xl mx-auto`,
          postsCount >= 2 && tw`lg:grid-cols-2`
        ]}
      >
        {category &&
          category.posts.nodes.map((post, index) => (
            <div
              key={post.id}
              css={[postsCount > 2 && index === 0 && tw`col-span-2`]}
            >
              <MiniPost
                colorScheme="dark"
                post={post}
                layout={postsCount === 1 ? 'vertical-small' : 'vertical'}
              />
            </div>
          ))}
      </div>
    </FlexSection>
  )
}

export default BlogPosts
